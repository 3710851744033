import React from "react";

function InstructionList({header, children}) {
    return <div className='left-margin'>
        <h4>{header}</h4>
        <ol className='instructions'>
            {children}
        </ol>
    </div>
}

export function GettingStarted() {
    return <>
        <div className='centered'>
            <h1>Getting Started</h1>
        </div>

        <div className='left-margin'>
            <h2>Raspberry Pi</h2>
        </div>

        <div className='left-margin'>
            <h3>Summary Instructions</h3>
            <ol className='instructions'>
                <li>Copy the WROLPi image to a micro SD card.</li>
                <li>Boot the Raspberry Pi</li>
                <li>Choose your own username and password (do not use the <i>wrolpi</i> user).</li>
                <li>Finish installation steps, then reboot.</li>
                <li>Login using the user you created above.</li>
                <li>Modify fstab to mount your external drive to <b>/media/wrolpi</b>
                    <pre>echo '/dev/sda1 /media/wrolpi auto defaults,nofail 0 0' | sudo tee -a /etc/fstab</pre>
                </li>
                <li>Finish the installation with the repair script: <pre>/opt/wrolpi/repair.sh</pre></li>
                <li>Reboot <pre>sudo reboot</pre></li>
                <li>Browse to your WROLPi interface at <b>http://wrolpi.local</b></li>
            </ol>
        </div>

        <div className='left-margin'>
            <h3>Comprehensive Instructions</h3>
        </div>

        <div className='left-margin'>
            <InstructionList header='1) Download'>
                <li className="step">Download the Raspberry Pi image from <a href="/">WROLPi.org</a></li>
            </InstructionList>

            <div className='left-margin'>
                <h4>2) Image SD Card</h4>

                <div className='left-margin'>
                    <h5>Linux</h5>
                    <ol className='instructions'>
                        <li>Plug in your SD card, find its device path with: <pre><b>sudo blkid</b></pre></li>
                        <li>Extract and copy the WROLPi image to your drive (<b>/dev/sdb</b> in this example):
                            <pre><b>xzcat WROLPi-v0.10-aarch64-desktop.img.xz | sudo dd of=/dev/sdb status=progress</b></pre>
                        </li>
                    </ol>

                    <h5>Windows</h5>
                    <ol className="instructions">
                        <li>Copy the WROLPi image to your micro SD card
                            using <a href='https://www.raspberrypi.com/software/'>Raspberry Pi Imager</a></li>
                    </ol>
                </div>
            </div>

            <InstructionList header='3) First boot'>
                <li>Unplug your Raspberry Pi</li>
                <li>Insert the micro SD card from step 2 into your Raspberry Pi</li>
                <li>Connect any peripherals to your Raspberry Pi.</li>
                <li>Boot your Raspberry Pi.</li>
                <li>Choose your Country/Language/Timezone.</li>
                <li>Choose your own username and password (do not use the <i>wrolpi</i> user).</li>
                <li>Skip "Wi-Fi Selection", it is not required.</li>
                <li>Choose your favorite browser.</li>
                <li>Skip "Raspberry Pi Connect".</li>
                <li>Skip "Update Software".</li>
                <li>Restart.</li>
                <li>Login using the user you created above.</li>
                <li>Launch the Terminal application</li>
                <ol className='instructions'>
                    <li>Modify fstab to mount your external drive to <b>/media/wrolpi</b>
                        <pre>echo '/dev/sda1 /media/wrolpi auto defaults,nofail 0 0' | sudo tee -a /etc/fstab</pre>
                    </li>
                    <li>Finish the installation with the repair script: <pre>/opt/wrolpi/repair.sh</pre></li>
                    <li>Reboot <pre>sudo reboot</pre></li>
                </ol>
            </InstructionList>

            <InstructionList header='4) WROLPi Interface'>
                <li>Join the Hotspot, or browse to <a href="https://wrolpi.local">https://wrolpi.local</a> or, the IP
                    address of your WROLPi!
                </li>
                <ul>
                    <li>If you want to view the WROLPi interface on a device within the same network,
                        browse to <a href="https://wrolpi.local">https://wrolpi.local</a></li>
                </ul>
            </InstructionList>

        </div>

        <div className='left-margin'>
            <h2>Debian</h2>
        </div>

        <div className='left-margin'>
            <h3>Summary Instructions</h3>
            <ol className='instructions'>
                <li>Copy the Debian WROLPi ISO to a thumb-drive.</li>
                <li>Insert the thumb-drive into the laptop, boot to the thumb-drive.
                    <ol>
                        <li>Select "Start Installer"</li>
                        <li>Install Debian 12.</li>
                    </ol>
                </li>
                <li>Unplug the thumb-drive after the installation has completed</li>
                <li>Login as the user you created during installation.</li>
                <li>Switch to the root user: <pre>su -</pre></li>
                <li>Finish the installation with the repair script: <pre>/opt/wrolpi/repair.sh</pre></li>
                <li>Reboot: <pre>reboot</pre></li>
                <li>Join the Hotspot, or browse to <a href="https://wrolpi.local">https://wrolpi.local</a> or, the IP
                    address of your WROLPi!
                </li>
            </ol>
        </div>

    </>
}
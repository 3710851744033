import React from "react";
import {ExternalLink} from "./Common";
import {Link} from "react-router-dom";

export function Home() {
    const features = [
        {
            header: 'Off-grid first',
            body: 'Access all your media, all features, all without internet.  Built for Raspberry Pi.'
        },
        {header: 'Open Source', body: 'Free, built with open-source tools.'},
        {header: 'Global Search', body: 'Search your videos, archives, Zims, files with one global search.'},
        {
            header: 'Videos',
            body: <p>Download, play, and search your videos from the <a href="https://github.com/yt-dlp/yt-dlp">most
                popular sites</a>.</p>
        },
        {
            header: 'Wikipedia',
            body: <p>Download full copies of <a href='https://library.kiwix.org'>popular Wikimedia projects </a>
                into compact, searchable Zim files.</p>
        },
        {
            header: 'Web Archives',
            body: <p>Download complete webpages into a convenient <a
                href='https://github.com/gildas-lormeau/SingleFile'>single HTML file</a>.</p>
        },
        {header: 'Maps', body: 'Download expansive maps, use them off-grid.'},
        {
            header: 'Ebooks / PDFs',
            body: <p>Easily search the contents of your <a href="https://en.wikipedia.org/wiki/EPUB">EPUBs</a> and PDFs.
            </p>
        },
        {
            header: 'Tags',
            body: <p>Organize your videos, archives, articles, files with customizable tags.</p>
        },
    ];

    const downloads = [
        {
            key: 'desktop',
            href: 'https://wrolpi.nyc3.cdn.digitaloceanspaces.com/WROLPi-v0.14.0-beta-aarch64-desktop.img.xz',
            title: 'Raspberry Pi',
            details: 'aarch64 (4.9GB)',
        },
        {
            key: 'debian',
            href: 'https://wrolpi.nyc3.cdn.digitaloceanspaces.com/WROLPi-v0.14.0-beta-amd64.iso',
            title: 'Debian',
            details: 'amd64 (4.2GB)',
        },
    ];

    return <>
        <div className='centered'>
            <h1>Create your own off-grid library</h1>
        </div>

        <div className='centered'>
            <ExternalLink href='https://www.youtube.com/watch?v=0xfMLNVFq2Y'>
                <img id='demo-video' src='/demo.jpg' alt='Watch Video Demo'/>
            </ExternalLink>
        </div>

        <div className='features'>
            {features.map(i => <div className='feature' key={i['header']}>
                <h2>{i['header']}</h2>
                {i['body']}
            </div>)}
        </div>

        <hr className='break'/>

        <div className='centered'>
            <h1>Get Started</h1>
        </div>

        <div className='left-margin'>
            <h2>Instructions</h2>
            <div>
                <Link to='/getting-started'>Read the installation instructions</Link>
            </div>
        </div>

        <div className='left-margin'>

            <h2>Download</h2>

            <div className='downloads'>
                {downloads.map(i => <div key={i.key} className='download-container'>
                    <a href={i.href}>
                        <div className='download-link'>
                            <strong>{i.title}</strong>
                            <br/>
                            <small>{i.details}</small>
                        </div>
                    </a>
                </div>)}
            </div>
        </div>

        <hr className='break'/>

        <div className='left-margin'>
            <h2>Screenshots</h2>
            <p><Link to='/screenshots'>Screenshots of the WROLPi app</Link></p>
        </div>

        <div className='left-margin'>
            <h2>FAQ</h2>
            <p><Link to='/faq'>See answers to common questions here</Link></p>
        </div>

        <hr className='break'/>

        <div className='left-margin'>
            <h3>Built With</h3>
            <ul>
                <li>
                    <a href='https://python.org' target='_blank' rel='noreferrer'>Python 3.11</a>
                    <ul>
                        <li><a href='https://sanic.dev/'>Sanic</a></li>
                        <li><a href='https://github.com/yt-dlp/yt-dlp' target='_blank' rel='noreferrer'>yt-dlp</a></li>
                        <li><a href='https://www.sqlalchemy.org/' target='_blank' rel='noreferrer'>SQLAlchemy</a></li>
                    </ul>
                </li>
                <li>
                    <a href='https://reactjs.org/' target='_blank' rel='noreferrer'>React</a>
                    <ul>
                        <li>
                            <a href='https://react.semantic-ui.com/' target='_blank' rel='noreferrer'>Semantic UI</a>
                        </li>
                    </ul>
                </li>
                <li><a href='https://github.com/gildas-lormeau/SingleFile' target='_blank'
                       rel='noreferrer'>SingleFile</a></li>
                <li><a href='https://www.openstreetmap.org' target='_blank' rel='noreferrer'>Open Street Map</a></li>
                <li><a href='https://www.kiwix.org' target='_blank' rel='noreferrer'>Kiwix</a></li>
            </ul>
        </div>

    </>
}
